import { anime } from '../main.js';

(function($){

  document.addEventListener("DOMContentLoaded", function() {
    
    //- Anime JS

    var path = anime.path('#motionPath path');
    var priceText = document.querySelector('.follow-path-text');
    var price = {
      price: 11.999
    }
    
    var motionPath = anime({
      targets: ['#motionPath .el', price],
      translateX: path('x'),
      translateY: path('y'),
      // rotate: path('angle'),
      easing: 'easeInOutSine',
      duration: 4000,
      // direction: 'alternate',
      loop: true,
      opacity: {
        value: 1, // 0 + 2 = '2turn'
        duration: 300,
        easing: 'easeInOutSine'
      },
      delay: 300,
      price: 23.999,
      update: function() {
        priceText.innerHTML = price.price.toFixed(3).replace(".", ",");
      }
    });
  });
})(jQuery);